import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PreviousReceiptList from "../../components/ValidatePurchase/PreviousReceiptList";
import { Container } from "react-bootstrap";
import CarouselCustom from "../../components/CarouselCustom";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../../data";
import ImageModal from "../../components/ImageModal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const CartDelivery = (props) => {
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;
	const [imageModal, setImageModal] = useState(false);
	const history = useHistory();
	const config = useSelector((state) => state.app.config);
	React.useEffect(() => {
		if (config.campaignState === "submissionExpired" || config.submissionEnded === "submissionExpired") {
			history.push("/");
		}
	}, [config]);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);

	const handleToggle = (e) => {
		const { id } = e.target;
		console.log(id);
		if (id === "toggleImageModal") {
			setImageModal(!imageModal);
		}
	};
	return (
		<>
			<CarouselCustom />
			<Container fluid className="pt-2">
				<div className="row justify-content-center mt-0 mb-5">
					<div className="col-md-9 col-xs-12 py-4">
						<PreviousReceiptList />
						<div className="row bg-grey py-3 px-2 mt-1">
							<div className="col-12 col-lg-6">
								<p className="ff-StdHeavy">{t("CARD_DELIVERY.HED1")}</p>
								<br />

								<div className="step">
									<div className="circle ff-StdHeavy circle-40">1</div>
									<div className="btn-span">{t("CARD_DELIVERY.CONFIRM")}</div>
								</div>
								<p className=" ml-4 pl-3 mt-1" dangerouslySetInnerHTML={{ __html: t("CARD_DELIVERY.STEP_1") }}></p>
								<p className="ff-StdHeavy mt-5">{t("CARD_DELIVERY.HED2")}</p>
								<div className="step">
									<div className="circle ff-StdHeavy">1</div>
									<p className="w-100" dangerouslySetInnerHTML={{ __html: t("CARD_DELIVERY.STEP_2") }}></p>
								</div>
								<div className="step">
									<div className="circle ff-StdHeavy">2</div>
									<p className="w-100" dangerouslySetInnerHTML={{ __html: t("CARD_DELIVERY.STEP_3") }}></p>
								</div>
							</div>
							<div className="col-12 col-lg-6">
								<p className="ff-StdHeavy">{t("CARD_DELIVERY.HED3")}</p>
								<p className="p-small" onClick={handleToggle} dangerouslySetInnerHTML={{ __html: t("CARD_DELIVERY.SUB_HED1") }}></p>
								{selected_lang === "en" ? <img className="w-100" src={commonImgData_en_exp.img11} alt={commonImgData_en_exp.title11} /> : <img className="w-100" src={commonImgData_fr_exp.img11} alt={commonImgData_fr_exp.title11} />}
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-0 mb-5">
					<div className="col-8 col-md-8 col-xs-12 py-4">
						<a href="https://www.costco.ca/pg.html" target="new">
							{selected_lang === "en" ? (
								<>
									<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img3} alt={commonImgData_en_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img3} alt={commonImgData_en_exp.title3} />
								</>
							) : (
								<>
									<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img3} alt={commonImgData_fr_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img3} alt={commonImgData_fr_exp.title3} />
								</>
							)}
						</a>
					</div>
				</div>
			</Container>
			<ImageModal isOpen={imageModal} handleClose={() => handleToggle({ target: { id: "toggleImageModal" } })} src={selected_lang === "en" ? commonImgData_en_exp.img11 : commonImgData_fr_exp.img11} alt={selected_lang === "en" ? commonImgData_en_exp.title10 : commonImgData_fr_exp.title11} />
		</>
	);
};

export default CartDelivery;

import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CarouselCustom from "./CarouselCustom";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../data";

const UpcomingCampaign = (props) => {
	const { t, i18n } = useTranslation();
	const config = useSelector((state) => state.app.config);
	const history = useHistory();
	const selected_lang = i18n.language;
	useEffect(() => {
		if (config.campaignState !== "coming") {
			history.push(`/`);
		}
	}, [config]);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<Container fluid className="pt-5">
				<h1>{t("COMMON.UPCOMMING_HEADER")}</h1>
				<h2 className="text-center ff-StdHeavy">{t("COMMON.UPCOMMING_SUBHEADER")}</h2>
				<div className="row justify-content-center my-5">
					<div className="col-md-12 col-xs-12">
						{selected_lang == "en" ? (
							<>
								<div className="row justify-content-center bg-blue desktop-hide px-5 py-3">
									<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img1} alt={commonImgData_en_exp.title1} />
								</div>
								<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img1} alt={commonImgData_en_exp.title1} />
							</>
						) : (
							<>
								<div className="row justify-content-center bg-blue desktop-hide px-5 py-3">
									<img className="d-block w-100 banner-img desktop-hide bg-blue" src={commonImgData_fr_exp.img1} alt={commonImgData_fr_exp.title1} />
								</div>
								<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img1} alt={commonImgData_fr_exp.title1} />
							</>
						)}
					</div>
					<div className="mb-5 mt-3 col-12">
						<h3 className="text-center" dangerouslySetInnerHTML={{ __html: t("COMMON.PB") }}></h3>
						<p className="text-center p-small" dangerouslySetInnerHTML={{ __html: t("COMMON.PB_SUB") }}></p>
					</div>
					<div className="col-md-8 col-xs-12">
						{selected_lang == "en" ? (
							<>
								<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img2} alt={commonImgData_en_exp.title2} />
								<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img2} alt={commonImgData_en_exp.title2} />
							</>
						) : (
							<>
								<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img2} alt={commonImgData_fr_exp.title2} />
								<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img2} alt={commonImgData_fr_exp.title2} />
							</>
						)}
					</div>
					<div className="col-12 col-sm-10">
						<p className="mt-5 upcoming-des" dangerouslySetInnerHTML={{ __html: t("COMMON.DESCRIPTION") }} />
					</div>
					<div className="col-md-8 col-xs-12 py-4">
						<a href="https://www.costco.ca/pg.html" target="new">
							{selected_lang == "en" ? (
								<>
									<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img3} alt={commonImgData_en_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img3} alt={commonImgData_en_exp.title3} />
								</>
							) : (
								<>
									<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img3} alt={commonImgData_fr_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img3} alt={commonImgData_fr_exp.title3} />
								</>
							)}
						</a>
					</div>
				</div>
			</Container>
		</>
	);
};

export default UpcomingCampaign;

import React, { useState } from "react";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useTranslation } from "react-i18next";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../../data";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import PreviousReceiptList from "../../components/ValidatePurchase/PreviousReceiptList";
const LoggedIn = (props) => {
	const { i18n } = useTranslation();
	const selected_lang = i18n.language;
	const config = useSelector((state) => state.app.config);
	return (
		<Container fluid className="pt-2">
			<div className="row justify-content-center mt-0 mb-5">
				<div className="col-md-9 col-xs-12 py-4">
					<PreviousReceiptList />
					{config.campaignState !== "submissionExpired" && config.submissionEnded !== "submissionExpired" && (
						<div className="row justify-content-center mt-0 mt-5 welcome-img-container">
							<div className="col-md-4 col-xs-12 my-2">
								<div className="">
									<a href="/upload-receipt">{selected_lang == "en" ? <img className="w-100 h-100" src={commonImgData_en_exp.img13} alt={commonImgData_en_exp.title13} /> : <img className="w-100 h-100" src={commonImgData_fr_exp.img13} alt={commonImgData_fr_exp.title13} />}</a>
								</div>
							</div>
							<div className="col-md-4 col-xs-12 my-2">
								<div className="">
									<a href="/submission-confirmation">{selected_lang == "en" ? <img className="w-100 h-100" src={commonImgData_en_exp.img14} alt={commonImgData_en_exp.title14} /> : <img className="w-100 h-100" src={commonImgData_fr_exp.img14} alt={commonImgData_fr_exp.title14} />}</a>{" "}
								</div>
							</div>
							<div className="col-md-4 col-xs-12 my-2">
								<div className="">
									<a href="/cart-delivery">{selected_lang == "en" ? <img className="w-100 h-100" src={commonImgData_en_exp.img15} alt={commonImgData_en_exp.title15} /> : <img className="w-100 h-100" src={commonImgData_fr_exp.img15} alt={commonImgData_fr_exp.title15} />}</a>{" "}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{config.campaignState !== "submissionExpired" && config.submissionEnded !== "submissionExpired" && (
				<div className="row justify-content-center mt-0 mb-5">
					<div className="col-8 col-md-8 col-xs-12 py-4">
						<a href="https://www.costco.ca/pg.html" target="new">
							{selected_lang == "en" ? (
								<>
									<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img3} alt={commonImgData_en_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img3} alt={commonImgData_en_exp.title3} />
								</>
							) : (
								<>
									<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img3} alt={commonImgData_fr_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img3} alt={commonImgData_fr_exp.title3} />
								</>
							)}
						</a>
					</div>
				</div>
			)}
		</Container>
	);
};

export default IsLoadingHOC(LoggedIn, "");

import React, { useState } from "react";
import UploadReceiptImage from "../../components/ValidatePurchase/UploadReceiptImage";
import { useTranslation } from "react-i18next";
import PreviousReceiptList from "../../components/ValidatePurchase/PreviousReceiptList";
import { Container } from "react-bootstrap";
import CarouselCustom from "../../components/CarouselCustom";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../../data";
import VideoModal from "../../components/VideoModal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const UploadReceipt = (props) => {
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;
	const [showVideoModal, setShowVideoModal] = useState(false);
	const history = useHistory();
	const config = useSelector((state) => state.app.config);
	React.useEffect(() => {
		if (config.campaignState === "submissionExpired" || config.submissionEnded === "submissionExpired") {
			history.push("/");
		}
	}, [config]);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	const handleToggleModal = () => {
		setShowVideoModal(!showVideoModal);
	};
	return (
		<>
			<CarouselCustom />
			<Container fluid className="pt-2">
				<div className="row justify-content-center mt-0 mb-5">
					<div className="col-md-9 col-xs-12 py-4">
						<PreviousReceiptList />
						<div className="row bg-light-grey p-0 pt-3 py-md-3 px-md-2 px-md-5 mt-5">
							<div className="col-12 col-lg-7">
								<a className="ff-StdHeavy blue text-center p-small">{t("UPLOAD_RECEIPT.HED1")}</a>
								<p className="ff-StdHeavy mt-2" dangerouslySetInnerHTML={{ __html: t("UPLOAD_RECEIPT.HED2") }}></p>
								<div className="row">
									<div className="col-md-6 mb-5 m-md-0 p-md-0">
										<p className="p-small">{t("UPLOAD_RECEIPT.SUB_HED1")}</p>
										<div className="step">
											<div className="circle ff-StdHeavy">1</div>
											<p className="ff-StdHeavy p-small" dangerouslySetInnerHTML={{ __html: t("UPLOAD_RECEIPT.STEP_1") }}></p>
										</div>

										<div className="step">
											<div className="circle ff-StdHeavy">2</div>
											<p className="ff-StdHeavy p-small" dangerouslySetInnerHTML={{ __html: t("UPLOAD_RECEIPT.STEP_2") }}></p>
										</div>
										<div className="step">
											<div className="circle ff-StdHeavy">3</div>
											<p className="ff-StdHeavy p-small" dangerouslySetInnerHTML={{ __html: t("UPLOAD_RECEIPT.STEP_3") }}></p>
										</div>
										<div className="step">
											<div className="circle ff-StdHeavy">4</div>
											<p className="ff-StdHeavy p-small" dangerouslySetInnerHTML={{ __html: t("UPLOAD_RECEIPT.STEP_4") }}></p>
										</div>
										<p className="p-small my-2">{t("UPLOAD_RECEIPT.SUB_HED2")}</p>
									</div>
									<div className="col-md-6 my-4 pl-4 pr-5 m-md-0 p-md-0 pr-md-3">{selected_lang === "en" ? <img className="w-100" src={commonImgData_en_exp.img12} alt={commonImgData_en_exp.title12} /> : <img className="w-100" src={commonImgData_fr_exp.img12} alt={commonImgData_fr_exp.title12} />}</div>
								</div>
							</div>
							<div className="col-12 col-lg-5 p-0 px-md-2">
								<div className="bg-grey h-100">
									<div className="mb-0 bg-dark-blue px-1 h-20"></div>
									<UploadReceiptImage />
								</div>
							</div>
						</div>
						<div className="row  bg-dark-blue py-md-4">
							<div className="col-12">
								<div className="d-flex w-100 flex-column align-items-center py-3">
									<button className="btn p-2 upload-btn-video  mt-0 ff-StdHeavy px-md-5 py-2 fs-20" onClick={handleToggleModal}>
										{t("UPLOAD_RECEIPT.WATCH_BTN")} {selected_lang === "en" ? <img className="position-unset d-inline ml-md-3" src={commonImgData_en_exp.img4} alt={commonImgData_en_exp.title4} /> : <img className="position-unset d-inline ml-md-3" src={commonImgData_fr_exp.img4} alt={commonImgData_fr_exp.title4} />}
									</button>
								</div>
							</div>
						</div>
						<VideoModal isOpen={showVideoModal} handleClose={handleToggleModal} />
					</div>
				</div>
				<div className="row justify-content-center mt-0 mb-5">
					<div className="col-8 col-md-8 col-xs-12 py-4 align-items-center">
						<a href="https://www.costco.ca/pg.html" target="new">
							{selected_lang === "en" ? (
								<>
									<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img3} alt={commonImgData_en_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img3} alt={commonImgData_en_exp.title3} />
								</>
							) : (
								<>
									<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img3} alt={commonImgData_fr_exp.title3} />
									<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img3} alt={commonImgData_fr_exp.title3} />
								</>
							)}
						</a>
					</div>
				</div>
			</Container>
		</>
	);
};

export default UploadReceipt;

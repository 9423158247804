import React, { useState, useEffect } from "react";
import { Row, FormGroup } from "react-bootstrap";
// eslint-disable-next-line
import { signup, login, preRegistrationAction } from "../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "./IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import Calendar from "react-calendar";
import Required from "./Required";
import Captcha from "./Captcha";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { Select, MenuItem, InputLabel, FormControl, Input } from "@material-ui/core";
var validator = require("validator");
const Signup = ({ signup, setLoading, login }) => {
	// const Signup = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;
	const confige = useSelector((state) => state.app.config);
	const history = useHistory();
	const [viewWelcomeScreen, setViewWelcomeScreen] = useState(false);
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	React.useEffect(() => {
		if ((confige?.captchaConfig?.recaptchaVersion || "").toLowerCase() === "v3") {
			const interval = setInterval(() => {
				setResetCaptchaCnt(resetCaptchaCnt + 1);
			}, 45000);
			return () => clearInterval(interval);
		}
	}, [resetCaptchaCnt, confige]);

	const termConditionChkHandler = (checked, name) => {
		let errorArray = JSON.parse(JSON.stringify(error));
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			if (name === "agree_to_terms") {
				errorArray[name] = "";
			}
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
			if (name === "agree_to_terms") {
				errorArray[name] = "REGISTER.ERROR.TNC_CHECKBOX";
			}
		}
		setError(errorArray);
		setData(d);
	};

	const newsLetterChkHandler = (checked, name) => {
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
		}
		setData(d);
	};

	const errorMessages = {
		firstName: { reqMsg: "REGISTER.ERROR.FIRST_NAME_REQ_MSG", invalidMsg: "REGISTER.ERROR.FIRST_NAME_INVALID_MSG" },
		lastName: { reqMsg: "REGISTER.ERROR.LAST_NAME_REQ_MSG", invalidMsg: "REGISTER.ERROR.LAST_NAME_INVALID_MSG" },
		email: { reqMsg: "REGISTER.ERROR.EMAIL_REQ_MSG", invalidMsg: "REGISTER.ERROR.EMAIL_INVALID_MSG" },
		confirmEmail: { reqMsg: "REGISTER.ERROR.EMAIL_REQ_MSG", invalidMsg: "REGISTER.ERROR.EMAIL_INVALID_MSG" },
		city: { reqMsg: "REGISTER.ERROR.CITY_REQ_MSG", invalidMsg: "REGISTER.ERROR.CITY_INVALID_MSG" },
		state: { reqMsg: "REGISTER.ERROR.STATE_REQ_MSG", invalidMsg: "REGISTER.ERROR.STATE_INVALID_MSG" },
		memberId: { reqMsg: "REGISTER.ERROR.MEMBERSHIPID_REQ_MSG", invalidMsg: "REGISTER.ERROR.MEMBERSHIPID_INVALID_MSG" },
		address: { reqMsg: "REGISTER.ERROR.ADDRESS_REQ_MSG", invalidMsg: "REGISTER.ERROR.ADDRESS_INVALID_MSG" },
		city: { reqMsg: "REGISTER.ERROR.CITY_REQ_MSG", invalidMsg: "REGISTER.ERROR.CITY_INVALID_MSG" },
		password: { reqMsg: "REGISTER.ERROR.PASS_REQ_MSG", invalidMsg: "REGISTER.ERROR.PASS_INVALID_MSG" },
		passwordConfirm: { reqMsg: "REGISTER.ERROR.PASS_REQ_MSG", invalidMsg: "REGISTER.ERROR.PASS_INVALID_MSG" },
		zip: { reqMsg: "REGISTER.ERROR.ZIP_REQ_MSG", invalidMsg: "REGISTER.ERROR.ZIP_INVALID_MSG" },
		cellPhone: { invalidMsg: "REGISTER.ERROR.CELL_INVALID_MSG" },
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		memberId: "",
		firstName: "",
		lastName: "",
		address: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		email: "",
		confirmEmail: "",
		password: "",
		passwordConfirm: "",
		city: "",
		state: "",
		cellPhone: "",
		latitude: 0,
		longitude: 0,
		additionalInfo: {
			visitorId: "",
			requestId: "",
			agree_to_terms: false,
			agree_to_subscribe: false,
			agree_to_personal_data: false,
			newsletter_subscribe: false,
			language: selected_lang,
			domain: window.location.origin,
		},
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({
		memberId: "",
		firstName: "",
		lastName: "",
		address: "",
		city: "",
		state: "",
		zip: "",
		cellPhone: "",
		password: "",
		passwordConfirm: "",
		mail: "",
		confirmEmail: "",
		city: "",
		state: "",
		agree_to_terms: "",
	});
	useEffect(() => {
		let items = {};
		let oldState = { ...data, password: "", passwordConfirm: "" };
		setData(oldState);
		items.password = confige.uiConfig.passwordRule;
		items.passwordConfirm = confige.uiConfig.passwordRule;
		if (confige.campaignState === "partialExpired" || confige.campaignState === "expired" || confige.campaignState === "submissionExpired" || confige.submissionEnded === "submissionExpired") {
			history.push(`/`);
			toast.error(t("COMMON.PARTIAL_EXPIRED_MSG"));
		}
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				setCaptchaEnable(true);
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		
		if (name === "memberId") {
			if (isNaN(value) || value.indexOf(".") >= 0) {
				return false;
			}
		}
		if (name === "cellPhone") {
			if (isNaN(value) || value.indexOf(".") >= 0) {
				return false;
			}
			if (value.length >= 1  && value.length < 10) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CELL_INVALID_MSG");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
		}
		
	
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_opt_in = e.target.checked;
			setData(d);
			return true;
		}

		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MIN_LENGTH", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MAX_LENGTH", {
								count: validationArray[key],
						  });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.NOT_EMPTY");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("REGISTER.ERROR.EMAIL_REQ_MSG");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.NOT_CONTAINS_NUMBER");
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CONTAINS_NUMBER");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);

	const onSubmit = async () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("REGISTER.ERROR.EMAIL_REQ_MSG");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MIN_LENGTH", {
								count: validationArray[key],
						  });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MAX_LENGTH", {
								count: validationArray[key],
						  });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.NOT_CONTAINS_NUMBER");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CONTAINS_NUMBER");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.NOT_EMPTY");
				}
			}
			errorArray[name] = errorMessage;

			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("REGISTER.ERROR.EMAIL_MATCH");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
			if (name === "passwordConfirm") {
				if (data["password"] !== data["passwordConfirm"]) {
					errorMessage = t("REGISTER.ERROR.PASS_MATCH");
				}
				errorArray["passwordConfirm"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = "REGISTER.ERROR.CAPTCHA_VAL";
		}
		if (!data.additionalInfo.agree_to_terms) {
			errorArray["agree_to_terms"] = "REGISTER.ERROR.TNC_CHECKBOX";
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}

		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			newData["additionalInfo"]["language"] = i18n.language.toUpperCase();

			setLoading(true);
			// props.setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoading(false);
							// props.setLoading(false);
						}
					} else {
						toast.error(t("COMMON.SOMTHING_WRONG"));
						setLoading(false);
						// props.setLoading(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid)
						.then((resp) => {
							setLoading(false);
							setViewWelcomeScreen(true);
						})
						.catch((err) => {
							if (Array.isArray(err)) {
								err.map((item) => {
									if (item?.errorCode?.length > 0) {
										toast.error(t(`REGISTER.ERROR.${item.errorCode}`));
									} else {
										toast.error(item.message);
									}
								});
							} else {
								toast.error(t("COMMON.SOMTHING_WRONG"));
							}
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
							// props.setLoading(false);
						});
				}
			} catch (error) {
				console.log("errror", error);
				setLoading(false);
				// props.setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						if (item?.errorCode?.length > 0) {
							toast.error(t(`REGISTER.ERROR.${item.errorCode}`));
						} else {
							toast.error(item.message);
						}
					});
				} else {
					toast.error(t("COMMON.SOMTHING_WRONG"));
				}
			}
		}
	};

	return (
		<div id="enternow" className="register-page">
			{!viewWelcomeScreen ? (
				<div className="p-0 col-sm-9">
					<form role="form" aria-label="Registration" className="signup-form">
						<h1 className="mt-4 black uppercase mb-0">{t("REGISTER.HEADING")}</h1>
						<div>
							{/* <p className="text-center">{t("REGISTER.sub_heading")}</p> */}
							<p className="text-center black mb-4">
								<Required />
								{t("REGISTER.sub_heading1")}
							</p>
						</div>
						<div className="mb-0 bg-light-blue px-1 h-20"></div>
						<div className="px-4 py-3 pb-5 mt-0 bg-grey">
							<Row className="mt-5">
								<div className="form-group col-12">
									<FormControl fullWidth variant="outlined">
										<InputLabel htmlFor="memberId" className="reg-input-lable">
											{validation["memberId"] && validation["memberId"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.MEMBERID")}
										</InputLabel>
										<Input autoComplete="true" value={data.memberId} disableUnderline fullwidth name="memberId" id="memberId" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-firstName" }} onChange={handleChange} />
										<span className="input-error">{t(error.memberId)}</span>
									</FormControl>
								</div>
							</Row>
							<a className="blue mb-2 d-inline-block p-small ff-StdHeavy" href="/find-memberid">
								<Trans>{`${t("REGISTER.FIND_MEMBERSHIP_NUMBER")}`}</Trans>
							</a>
							<Row>
								{/*[START:FIRSTNAME]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="firstName" className="reg-input-lable">
											{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.FIRST_NAME")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="firstName" id="firstName" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-firstName" }} onChange={handleChange} />
										<span className="input-error">{t(error.firstName)}</span>
									</FormControl>
								</div>

								{/*[END:FIRSTNAME]*/}

								{/*[START:LASTNAME]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="last-name" className="reg-input-lable">
											{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.LAST_NAME")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="lastName" id="last-name" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-lastName" }} onChange={handleChange} />
										<span className="input-error">{t(error.lastName)}</span>
									</FormControl>
								</div>

								{/*[END:LASTNAME]*/}
							</Row>
							<Row>
								{/*[START:STREET_ADDRESS]*/}
								<div className="form-group col-12">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="address" className="reg-input-lable">
											{validation["address"] && validation["address"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.STREETADDRESS")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="address" id="address" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-address" }} onChange={handleChange} />
										<span className="input-error">{t(error.address)}</span>
									</FormControl>
								</div>
								{/*[END:STREET_ADDRESS]*/}
							</Row>
							<Row>
								{/*[START:ADDRESS2]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="address2" className="reg-input-lable">
											{/* {validation["address2"] && validation["address2"].mandatory ? <Required /> : ""} */}
											{t("REGISTER.FIELDS.ADDRESS")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="address2" id="address2" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-address2" }} onChange={handleChange} />
										{/* <span className="input-error">{t(error.address2)}</span> */}
									</FormControl>
								</div>

								{/*[END:ADDRESS2]*/}

								{/*[START:PHONE]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="cellPhone" className="reg-input-lable">
											{t("REGISTER.FIELDS.PHONE")}
										</InputLabel>
										<Input autoComplete="true" value={data.cellPhone} disableUnderline fullWidth name="cellPhone" id="cellPhone" aria-describedby="my-helper-text" maxLength={10} className="reg-input-field" inputProps={{ "data-test": "register-cellPhone", maxLength: 10 }} onChange={handleChange} />
										<span className="input-error">{t(error.cellPhone)}</span>
									</FormControl>
								</div>
								{/*[END:PHONE]*/}
							</Row>

							<Row>
								{/*[START:CITY]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="city" className="reg-input-lable">
											{validation["city"] && validation["city"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.CITY")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="city" id="city" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-city" }} onChange={handleChange} />
										<span className="input-error">{t(error.city)}</span>
									</FormControl>
								</div>

								{/*[END:CITY]*/}

								{/*[START:PR]*/}

								<div className="form-group col-sm-4">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth id="PR" className="reg-input-lable" htmlFor="PR-Input">
											{validation["state"] && validation["state"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.PR")}
										</InputLabel>
										<Select labelId="PR" inputProps={{ id: "PR-Input" }} name="state" onChange={handleChange} variant="standard" disableUnderline>
											<MenuItem value="AB">{t("REGISTER.FIELDS.ALBERTA")}</MenuItem>
											<MenuItem value="BC">{t("REGISTER.FIELDS.BRITISH_COLUMBIA")}</MenuItem>
											<MenuItem value="MB">{t("REGISTER.FIELDS.MANITOBA")}</MenuItem>
											<MenuItem value="NB">{t("REGISTER.FIELDS.NEW_BRUNSWICK")}</MenuItem>
											<MenuItem value="NL">{t("REGISTER.FIELDS.NEWFOUNDLAND_LABRADOR")}</MenuItem>
											<MenuItem value="NT">{t("REGISTER.FIELDS.NORTHWEST_TERRITORIES")}</MenuItem>
											<MenuItem value="NS">{t("REGISTER.FIELDS.NOVA_SCOTIA")}</MenuItem>
											<MenuItem value="NU">{t("REGISTER.FIELDS.NUNAVUT")}</MenuItem>
											<MenuItem value="ON">{t("REGISTER.FIELDS.ONTARIO")}</MenuItem>
											<MenuItem value="PE">{t("REGISTER.FIELDS.PRINCE_EDWARD_ISLAND")}</MenuItem>
											<MenuItem value="QC">{t("REGISTER.FIELDS.QUEBEC")}</MenuItem>
											<MenuItem value="SK">{t("REGISTER.FIELDS.SASKATCHEWAN")}</MenuItem>
											<MenuItem value="YT">{t("REGISTER.FIELDS.YUKON")}</MenuItem>
										</Select>
										{/* <Input autoComplete="true"     disableUnderline fullWidth name="state" id="PR" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-state" }} onChange={handleChange} /> */}
										<span className="input-error">{t(error.state)}</span>
									</FormControl>
								</div>
								{/*[END:PR]*/}
								{/*[START:POSTALCODE]*/}
								<div className="form-group col-sm-2">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="zip-code" className="reg-input-lable">
											{validation["zip"] && validation["zip"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.PIN")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="zip" id="zip-code" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-zip" }} onChange={handleChange} />
										<span className="input-error">{t(error.zip)}</span>
									</FormControl>
								</div>

								{/*[END:POSTALCODE]*/}
							</Row>

							<Row className="mt-3">
								{/*[START:EMAIL]*/}

								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="email" className="reg-input-lable">
											{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.EMAIL")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="email" id="email" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-email" }} onChange={handleChange} />
										<span className="input-error">{t(error.email)}</span>
									</FormControl>
								</div>
								{/*[END:EMAIL]*/}
								{/*[START:EMAIL CONFIRM]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="email-confirm" className="reg-input-lable">
											{validation["confirmEmail"] && validation["confirmEmail"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.CONFIRM_EMAIL")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth name="confirmEmail" id="email-confirm" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-confirmEmail" }} onChange={handleChange} />
										<span className="input-error">{t(error.confirmEmail)}</span>
									</FormControl>
								</div>
								{/*[END:EMAIL CONFIRM]*/}
							</Row>
							<Row className="">
								{/*[START:PASSWORD]*/}

								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="password" className="reg-input-lable">
											{validation["password"] && validation["password"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.PASS")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline fullWidth type="password" name="password" id="password" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-password" }} onChange={handleChange} />
										<span className="input-error">{t(error.password)}</span>
									</FormControl>
								</div>
								{/*[END:PASSWORD]*/}
								{/*[START:PASSWORD CONFIRM]*/}
								<div className="form-group col-sm-6">
									<FormControl fullWidth variant="outlined">
										<InputLabel fullWidth htmlFor="pass-confirm" className="reg-input-lable">
											{validation["passwordConfirm"] && validation["passwordConfirm"].mandatory ? <Required /> : ""}
											{t("REGISTER.FIELDS.CONFIRMPASS")}
										</InputLabel>
										<Input autoComplete="true" disableUnderline type="password" fullWidth name="passwordConfirm" id="pass-confirm" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-confirmEmail" }} onChange={handleChange} />
										<span className="input-error">{t(error.passwordConfirm)}</span>
									</FormControl>
								</div>
								{/*[END:PASSWORD CONFIRM]*/}
							</Row>
							<Row className="my-2">
								<div className="col-12">
									<p className="p-small letter-pacing-1 fs-14">{t("REGISTER.PASS_INSTRUCTION")}</p>
								</div>
							</Row>
							{/*[START:AGREE_TO_TERM]*/}
							<Row>
								<div className="mt-3 col-12">
									<div className="btm-1 d-flex v-align-baseline form-check">
										<input type="checkbox" id="agree_to_terms" name="agree_to_terms" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_terms")} />

										<label htmlFor="agree_to_terms" className="form-check-label p-small ff-StdBook">
											<Required /> <span dangerouslySetInnerHTML={{ __html: t("REGISTER.FIELDS.AGREE_TO_TERMS") }}></span>
										</label>
									</div>
									<span className="input-error">{t(error.agree_to_terms)}</span>
								</div>
							</Row>
							{/*[END:AGREE_TO_TERM]*/}
							{/*[START:AGREE_TO_SUBSCRIBE]*/}
							<Row>
								<div className="mt-3 col-12">
									<div className="btm-1 d-flex v-align-baseline form-check">
										<input type="checkbox" id="agree_to_subscribe" name="agree_to_subscribe" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_subscribe")} />

										<label htmlFor="agree_to_subscribe" className="form-check-label p-small ff-StdBook">
											<span dangerouslySetInnerHTML={{ __html: t("REGISTER.FIELDS.AGREE_TO_SUBSCRIBE") }}></span>
										</label>
									</div>
								</div>
							</Row>
							{/*[END:AGREE_TO_SUBSCRIBE]*/}
							{/*[START:NEWSLETTER_SUBSCRIBE]*/}
							<Row>
								<div className="mt-3 col-12">
									<div className="btm-1 d-flex v-align-baseline form-check">
										<input type="checkbox" id="newsletter_subscribe" name="newsletter_subscribe" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "newsletter_subscribe")} />

										<label htmlFor="newsletter_subscribe" className="form-check-label p-small ff-StdBook">
											<span dangerouslySetInnerHTML={{ __html: t("REGISTER.FIELDS.NEWSLETTER_SUBSCRIBE") }}></span>
										</label>
									</div>
								</div>
							</Row>
							{/*[END:NEWSLETTER_SUBSCRIBE]*/}
							{/*[START:AGREE_TO_PERSONAL_DATA]*/}
							<Row>
								<div className="mt-3 col-12">
									<div className="btm-1 d-flex v-align-baseline form-check">
										<input type="checkbox" id="agree_to_personal_data" name="agree_to_personal_data" className="form-check-input" onChange={(e) => termConditionChkHandler(e.target.checked, "agree_to_personal_data")} />

										<label htmlFor="agree_to_personal_data" className="form-check-label ff-StdBook">
											<p className="p-small" dangerouslySetInnerHTML={{ __html: t("REGISTER.FIELDS.AGREE_TO_PERSONAL_DATA") }}></p>
										</label>
									</div>
								</div>
							</Row>
							{/*[END:AGREE_TO_PERSONAL_DATA]*/}

							<Row>
								<div className="col-12 px-sm-5 mt-5 d-flex flex-column align-items-sm-center">
									<div className="px-md-5">
										<p className="fs-14 text-center ff-StdBook">{t("REGISTER.SUB_HEADING2")}</p>
									</div>
									<div className="d-flex align-items-md-center px-2 px-sm-5 flex-column">
										{/*[START:GOOGLECAPTCHA]*/}
										{captchaEnable ? (
											<div className="form-group mt-5">
												<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
													Google captcha
												</label>
												<div className="col-sm-12">
													<Captcha align={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
													<p className="input-error">{t(error.captcha)}</p>
												</div>
											</div>
										) : (
											""
										)}
										{/*[END:GOOGLECAPTCHA]*/}
										<div className="px-md-5">
											<p className="bold text-center" dangerouslySetInnerHTML={{ __html: t("REGISTER.BEFOR_BTN_MSG") }} />
											<div className="mt-3 text-center w-100">
												<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary w-100">
													{t("REGISTER.FIELDS.SUBMIT.LABEL")}
												</button>
											</div>
										</div>
									</div>
								</div>
							</Row>
						</div>
					</form>
					<p className="text-center my-5" dangerouslySetInnerHTML={{ __html: t("REGISTER.AFTER_BTN_MSG") }} />
				</div>
			) : (
				<div className="col-sm-9">
					<h1 className="my-4 styleColor black uppercase">{t("Thank_you")}!</h1>
					<div className="mb-0 bg-blue px-1 h-20"></div>
					<div className="px-5 py-3 mt-0 bg-grey">
						<h2 className="black font-30px uppercase my-4 text-center" dangerouslySetInnerHTML={{ __html: t("REGISTER.REG_SUCCESS_SUB_HEADING") }} />
						<p className="text-center">
							<a href="/" className="blue">
								{t("REGISTER.REG_SUCCESS_LINK")}
							</a>
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default connect(null, { signup, login })(IsLoadingHOC(Signup, "Wait ....."));
// export default IsLoadingHOC(Signup, "Wait .....");

import React, { useState } from "react";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useTranslation } from "react-i18next";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../../data";
import { Container } from "react-bootstrap";
import Login from "../Login";
import VideoModal from "../../components/VideoModal";
const NotLoggedIn = (props) => {
	const { t, i18n } = useTranslation();
	const [showVideoModal, setShowVideoModal] = useState(false);
	const selected_lang = i18n.language;
	const handleToggleModal = () => {
		setShowVideoModal(!showVideoModal);
	};
	return (
		<Container fluid className="pt-2">
			<div className="row justify-content-center">
				<div className="col-md-12 col-xs-12 bg-blue py-3">
					<h1 className="white pt-3">{t("COMMON.HOME_HEADER")}</h1>
				</div>
			</div>
			<div className="row justify-content-center my-2">
				<div className="col-md-6 col-xs-6 bg-light-grey py-5 text-center">
					<h2 className="text-center">{t("COMMON.HEADER_REGISTER")}</h2>
					<h3 className="text-center styleColor ff-StdBook">{t("COMMON.HEADER_REGISTER2")}</h3>
					<a href="/register" className="btn btn-primary btn-sm uppercase">
						{t("COMMON.BTN_LABEL")}
					</a>
				</div>
				<div className="col-md-6 col-xs-6 bg-grey pt-5">
					<Login />
				</div>
			</div>
			<div className="row mt-2 bg-gray-500">
				<div className="col-12 bg-gray p-md-5 p-4">
					<div className="d-flex w-100 flex-column align-items-center">
						<button className="btn btn-com-action mt-0 ff-StdHeavy" onClick={handleToggleModal}>
							{t("COMMON.QUESTION_VIDEO")} {selected_lang === "en" ? <img src={commonImgData_en_exp.img4} alt={commonImgData_en_exp.title4} /> : <img src={commonImgData_fr_exp.img4} alt={commonImgData_fr_exp.title4} />}
						</button>
						<a className="btn btn-com-action ff-StdHeavy" href={selected_lang === "en" ? commonImgData_en_exp.mail_form : commonImgData_fr_exp.mail_form} target="new">
							{t("COMMON.DOWNLOAD_FROM")} {selected_lang === "en" ? <img src={commonImgData_en_exp.img5} alt={commonImgData_en_exp.title5} /> : <img src={commonImgData_fr_exp.img5} alt={commonImgData_fr_exp.title5} />}
						</a>
					</div>
				</div>
			</div>

			<VideoModal isOpen={showVideoModal} handleClose={handleToggleModal} />

			<div className="row justify-content-center mt-0 mb-5">
				<div className="col-md-12 col-xs-12 toggle-banner-img">
					{selected_lang === "en" ? (
						<>
							<div className="row justify-content-center bg-blue px-5 py-3 desktop-hide">
								<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_en_exp.img1} alt={commonImgData_en_exp.title1} />
							</div>
							<img className="d-block w-100 mobile-hide banner-img bg-gray-500" src={commonImgData_en_exp.mobile_img1} alt={commonImgData_en_exp.title1} />
						</>
					) : (
						<>
							<div className="row justify-content-center bg-blue px-5 py-3 desktop-hide">
								<img className="d-block w-100 banner-img desktop-hide bg-blue" src={commonImgData_fr_exp.img1} alt={commonImgData_fr_exp.title1} />
							</div>
							<img className="d-block w-100 mobile-hide banner-img bg-gray-500" src={commonImgData_fr_exp.mobile_img1} alt={commonImgData_fr_exp.title1} />
						</>
					)}
				</div>
				<div className="mt-5 p-0 col-12 col-sm-9">
					<h3 className="text-center styleColor d-none d-sm-block" dangerouslySetInnerHTML={{ __html: t("COMMON.RUNNING_HEADER") }}></h3>
					<h3 className="text-center styleColor d-sm-none" dangerouslySetInnerHTML={{ __html: t("COMMON.RUNNING_MOB_HEADER") }}></h3>
					<p className="text-center p-small">{t("COMMON.RUNNING_SUBHEADER")}</p>
				</div>

				<div className="mb-5 mt-3 col-12">
					<h3 className="text-center" dangerouslySetInnerHTML={{ __html: t("COMMON.PB") }}></h3>
					<p className="text-center p-small" dangerouslySetInnerHTML={{ __html: t("COMMON.PB_SUB") }}></p>
				</div>

				<div className="col-md-8 col-xs-12">
					{selected_lang === "en" ? (
						<>
							<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img2} alt={commonImgData_en_exp.title2} />
							<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img2} alt={commonImgData_en_exp.title2} />
						</>
					) : (
						<>
							<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img2} alt={commonImgData_fr_exp.title2} />
							<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img2} alt={commonImgData_fr_exp.title2} />
						</>
					)}
				</div>
				<div className="col-12 col-sm-10">
					<p className="mt-5" dangerouslySetInnerHTML={{ __html: t("COMMON.DESCRIPTION") }} />
				</div>
				<div className="col-8 col-md-8 col-xs-12 py-4">
					<a href="https://www.costco.ca/pg.html" target="new">
						{selected_lang === "en" ? (
							<>
								<img className="d-block w-100 banner-img desktop-hide " src={commonImgData_en_exp.img3} alt={commonImgData_en_exp.title3} />
								<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_en_exp.mobile_img3} alt={commonImgData_en_exp.title3} />
							</>
						) : (
							<>
								<img className="d-block w-100 banner-img desktop-hide" src={commonImgData_fr_exp.img3} alt={commonImgData_fr_exp.title3} />
								<img className="d-block w-100 mobile-hide banner-img " src={commonImgData_fr_exp.mobile_img3} alt={commonImgData_fr_exp.title3} />
							</>
						)}
					</a>
				</div>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(NotLoggedIn, "");
